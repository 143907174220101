import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const UnitConverter = () => {
  const { t, i18n } = useTranslation();

  const quantities = [
    { id: 'length', name: t('length') },
    { id: 'mass', name: t('mass') },
    { id: 'temperature', name: t('temperature') },
    { id: 'time', name: t('time') },
    { id: 'current', name: t('current') },
    { id: 'luminousIntensity', name: t('luminousIntensity') },
    { id: 'amountOfSubstance', name: t('amountOfSubstance') },
    { id: 'area', name: t('area') },
    { id: 'volume', name: t('volume') },
    { id: 'density', name: t('density') },
    // ... altre quantità ...
  ];

  return (
    <>
      <Helmet>
        <title>{t('unitConverter.metaTitle')}</title>
        <meta name="description" content={t('unitConverter.metaDescription')} />
        <meta property="og:title" content={t('unitConverter.metaTitle')} />
        <meta property="og:description" content={t('unitConverter.metaDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.uniconverter.online/${i18n.language}`} />
        <link rel="canonical" href={`https://www.uniconverter.online/${i18n.language}`} />
      </Helmet>
      <div className="p-4 max-w-2xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {quantities.map((quantity) => (
            <Link
              key={quantity.id}
              to={`/convert/${quantity.id}`}
              className="block bg-gray-100 shadow-sm rounded-lg p-4 hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            >
              <div className="font-semibold text-lg text-gray-800">{quantity.name}</div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default UnitConverter;