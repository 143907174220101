// src/utils/conversions/siPrefixes.js

export const SI_PREFIXES = {
  yotta: 24,
  zetta: 21,
  exa: 18,
  peta: 15,
  tera: 12,
  giga: 9,
  mega: 6,
  kilo: 3,
  hecto: 2,
  deca: 1,
  deci: -1,
  centi: -2,
  milli: -3,
  micro: -6,
  nano: -9,
  pico: -12,
  femto: -15,
  atto: -18,
  zepto: -21,
  yocto: -24
};

export function applyPrefix(value, prefix) {
  if (!(prefix in SI_PREFIXES)) {
    throw new Error(`Prefisso non riconosciuto: ${prefix}`);
  }
  return value * Math.pow(10, SI_PREFIXES[prefix]);
}

export function removePrefix(value, prefix) {
  if (!(prefix in SI_PREFIXES)) {
    throw new Error(`Prefisso non riconosciuto: ${prefix}`);
  }
  return value * Math.pow(10, -SI_PREFIXES[prefix]);
}