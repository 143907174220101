import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('contact.metaTitle')}</title>
        <meta name="description" content={t('contact.metaDescription')} />
        <meta property="og:title" content={t('contact.metaTitle')} />
        <meta property="og:description" content={t('contact.metaDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.uniconverter.online/${i18n.language}/contact`} />
        <link rel="canonical" href={`https://www.uniconverter.online/${i18n.language}/contact`} />
      </Helmet>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">{t('contact.title')}</h1>
        <p className="mb-4">
          {t('contact.description')}
        </p>
        <div className="mb-2">
          <span className="font-semibold mr-2">{t('contact.email')}:</span>
          <a 
            href="mailto:info.uniconverter@gmail.com" 
            className="text-blue-600 hover:underline"
            aria-label={t('contact.emailAriaLabel')}
          >
            info.uniconverter@gmail.com
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;