import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  convertTimeWithScientificNotation,
  timeUnits,
  unitTranslations
} from '../utils/conversions/time';

const StandardTimeConversion = ({ onConvert }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [fromUnit, setFromUnit] = useState('seconds');
  const [toUnit, setToUnit] = useState('seconds');
  const [result, setResult] = useState(null);

  useEffect(() => {
    console.log('StandardTimeConversion rendered, onConvert is:', typeof onConvert);
  }, [onConvert]);

  const handleConvert = () => {
    console.log('handleConvert called in StandardTimeConversion');
    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      alert(t('erroreNumeroNonValido'));
      return;
    }
    const conversionResult = convertTimeWithScientificNotation(numValue, fromUnit, toUnit);
    
    let scientificNotationString = '';
    if (conversionResult.scientificNotation) {
      if (typeof conversionResult.scientificNotation === 'object') {
        scientificNotationString = `${conversionResult.scientificNotation.coefficient.toFixed(6)} × 10^${conversionResult.scientificNotation.exponent}`;
      } else {
        scientificNotationString = conversionResult.scientificNotation.toString();
      }
    }
    
    const resultObj = {
      ...conversionResult,
      scientificNotation: scientificNotationString
    };
    setResult(resultObj);

    // Aggiungi la conversione alla cronologia
    if (typeof onConvert === 'function') {
      console.log('Calling onConvert in StandardTimeConversion');
      onConvert('standard', value, fromUnit, conversionResult.formattedValue, toUnit);
    } else {
      console.error('onConvert is not a function in StandardTimeConversion');
    }
  };

  return (
    <div className="space-y-4">
      <input
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="w-full p-2 border rounded"
        placeholder={t('inserisciValore')}
      />
      <div className="flex space-x-2">
        <select
          value={fromUnit}
          onChange={(e) => setFromUnit(e.target.value)}
          className="w-1/2 p-2 border rounded"
        >
          {timeUnits.map(unit => (
            <option key={unit.name} value={unit.name}>
              {t(unitTranslations[unit.name])} ({unit.symbol})
            </option>
          ))}
        </select>
        <select
          value={toUnit}
          onChange={(e) => setToUnit(e.target.value)}
          className="w-1/2 p-2 border rounded"
        >
          {timeUnits.map(unit => (
            <option key={unit.name} value={unit.name}>
              {t(unitTranslations[unit.name])} ({unit.symbol})
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleConvert}
        className="w-full p-2 bg-purple-500 text-white font-semibold py-3 px-6 rounded"
      >
        {t('converti')}
      </button>
      {result && (
        <div className="mt-4 p-2 bg-gray-100 rounded">
          <p>{t('risultato')}: {result.formattedValue} {t(unitTranslations[toUnit])}</p>
          <p>{t('scientificNotation')}: {result.scientificNotation}</p>
        </div>
      )}
    </div>
  );
};

export default StandardTimeConversion;