import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  convertTimeWithScientificNotation,
  timeUnits,
  unitTranslations
} from '../utils/conversions/time';

const CompositeTimeConversion = ({ onConvert }) => {
  const { t } = useTranslation();
  const [compositeInput, setCompositeInput] = useState({ hours: '', minutes: '', seconds: '' });
  const [singleInput, setSingleInput] = useState('');
  const [singleUnit, setSingleUnit] = useState('seconds');
  const [result, setResult] = useState('');

  useEffect(() => {
    console.log('CompositeTimeConversion rendered, onConvert is:', typeof onConvert);
  }, [onConvert]);

  const handleCompositeToSingle = () => {
    console.log('handleCompositeToSingle called in CompositeTimeConversion');
    const totalSeconds = 
      (parseInt(compositeInput.hours) || 0) * 3600 + 
      (parseInt(compositeInput.minutes) || 0) * 60 + 
      (parseInt(compositeInput.seconds) || 0);

    const conversionResult = convertTimeWithScientificNotation(totalSeconds, 'seconds', singleUnit);
    
    const resultText = `${conversionResult.formattedValue} ${t(unitTranslations[singleUnit])} (${t('scientificNotation')}: ${conversionResult.scientificNotation})`;
    setResult(resultText);

    if (typeof onConvert === 'function') {
      console.log('Calling onConvert in CompositeTimeConversion (compositeToSingle)');
      onConvert('compositeToSingle', `${compositeInput.hours}h ${compositeInput.minutes}m ${compositeInput.seconds}s`, 'composite', conversionResult.formattedValue, singleUnit);
    } else {
      console.error('onConvert is not a function in CompositeTimeConversion (compositeToSingle)');
    }
  };

  const handleSingleToComposite = () => {
    console.log('handleSingleToComposite called in CompositeTimeConversion');
    const numValue = parseFloat(singleInput);
    if (isNaN(numValue)) {
      alert(t('erroreNumeroNonValido'));
      return;
    }

    const conversionResult = convertTimeWithScientificNotation(numValue, singleUnit, 'seconds');
    const totalSeconds = conversionResult.value;

    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor(totalSeconds % 60);

    const resultText = `${h}${t('ore')} ${m}${t('minuti')} ${s}${t('secondi')}`;
    setResult(resultText);

    if (typeof onConvert === 'function') {
      console.log('Calling onConvert in CompositeTimeConversion (singleToComposite)');
      onConvert('singleToComposite', singleInput, singleUnit, resultText, 'composite');
    } else {
      console.error('onConvert is not a function in CompositeTimeConversion (singleToComposite)');
    }
  };

  return (
    <div className="space-y-4">
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">{t('conversioneTempoCompostoSingola')}</h3>
        <div className="flex space-x-2">
          <input
            type="number"
            value={compositeInput.hours}
            onChange={(e) => setCompositeInput({...compositeInput, hours: e.target.value})}
            className="w-1/3 p-2 border rounded"
            placeholder={t('ore')}
          />
          <input
            type="number"
            value={compositeInput.minutes}
            onChange={(e) => setCompositeInput({...compositeInput, minutes: e.target.value})}
            className="w-1/3 p-2 border rounded"
            placeholder={t('minuti')}
          />
          <input
            type="number"
            value={compositeInput.seconds}
            onChange={(e) => setCompositeInput({...compositeInput, seconds: e.target.value})}
            className="w-1/3 p-2 border rounded"
            placeholder={t('secondi')}
          />
        </div>
        <select
          value={singleUnit}
          onChange={(e) => setSingleUnit(e.target.value)}
          className="w-full mt-2 p-2 border rounded"
        >
          {timeUnits.map(unit => (
            <option key={unit.name} value={unit.name}>
              {t(unitTranslations[unit.name])} ({unit.symbol})
            </option>
          ))}
        </select>
        <button
          onClick={handleCompositeToSingle}
          className="w-full mt-2 p-2 bg-emerald-500 text-white font-semibold py-3 px-6 rounded"
        >
          {t('converti')}
        </button>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">{t('conversioneSingolaTempoComposto')}</h3>
        <input
          type="number"
          value={singleInput}
          onChange={(e) => setSingleInput(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder={t('inserisciValore')}
        />
        <select
          value={singleUnit}
          onChange={(e) => setSingleUnit(e.target.value)}
          className="w-full mt-2 p-2 border rounded"
        >
          {timeUnits.map(unit => (
            <option key={unit.name} value={unit.name}>
              {t(unitTranslations[unit.name])} ({unit.symbol})
            </option>
          ))}
        </select>
        <button
          onClick={handleSingleToComposite}
          className="w-full mt-2 p-2 bg-orange-400 text-white font-semibold py-3 px-6 rounded"
        >
          {t('converti')}
        </button>
      </div>

      {result && (
        <div className="mt-4 p-2 bg-gray-100 rounded">
          <p>{t('risultato')}: {result}</p>
        </div>
      )}
    </div>
  );
};

export default CompositeTimeConversion;