import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StandardTimeConversion from './StandardTimeConversion';
import CompositeTimeConversion from './CompositeTimeConversion';
import { unitTranslations } from '../utils/conversions/time';

const TimeConversion = () => {
  const { t, i18n } = useTranslation();
  const [conversionHistory, setConversionHistory] = useState([]);
  const historyRef = useRef(null);

  useEffect(() => {
    console.log('TimeConversion rendering, language:', i18n.language);
    console.log('Current conversion history:', conversionHistory);
    
    if (historyRef.current) {
      historyRef.current.scrollTop = 0;
    }
  }, [i18n.language, conversionHistory]);

  const addToHistory = useCallback((conversionType, fromValue, fromUnit, toValue, toUnit) => {
    console.log('addToHistory called with:', { conversionType, fromValue, fromUnit, toValue, toUnit });
    const newEntry = {
      type: conversionType,
      from: `${fromValue} ${t(unitTranslations[fromUnit] || fromUnit)}`,
      to: `${toValue} ${t(unitTranslations[toUnit] || toUnit)}`,
      timestamp: new Date().toLocaleString()
    };
    setConversionHistory(prevHistory => [newEntry, ...prevHistory.slice(0, 4)]);
  }, [t]);

  const debugCallback = useCallback((type, ...args) => {
    console.log(`Debug from ${type}:`, ...args);
  }, []);

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-8 text-center">{t('convertiTempo')}</h1>
      
      <div className="flex flex-col md:flex-row gap-8">
        {/* Conversione Tempo Standard */}
        <div className="flex-1">
          <h2 className="text-xl font-semibold mb-4">{t('conversioneTempoStandard')}</h2>
          <StandardTimeConversion onConvert={addToHistory} debug={debugCallback} />
        </div>

        {/* Conversione Tempo Composto */}
        <div className="flex-1">
          <h2 className="text-xl font-semibold mb-4">{t('conversioneTempoComposto')}</h2>
          <CompositeTimeConversion onConvert={addToHistory} debug={debugCallback} />
        </div>
      </div>

      {/* Cronologia */}
      <div className="mt-8 p-4 bg-yellow-100 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">{t('cronologiaConversioni')}</h2>
        <div 
          ref={historyRef}
          className="max-h-60 overflow-y-auto"
          style={{scrollBehavior: 'smooth'}}
        >
          {conversionHistory.length > 0 ? (
            <ul className="space-y-2">
              {conversionHistory.map((entry, index) => (
                <li key={index} className="p-2 bg-white rounded shadow">
                  <p className="font-medium">{t(entry.type)}: {entry.from} → {entry.to}</p>
                  <p className="text-sm text-gray-500">{entry.timestamp}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('nessunaCronologia')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeConversion;