import React, { useEffect, useState, useCallback } from 'react';

const Advertisements = () => {
  const [adStatus, setAdStatus] = useState('loading');

  const loadScript = useCallback((src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    const maxRetries = 3;
    const retryDelay = 2000; // 2 seconds
    let retryCount = 0;

    const loadAds = async () => {
      const scripts = [
        'https://veepteero.com/88/tag.min.js',
        'https://pertawee.net/pfe/current/tag.min.js?z=8008193',
        'https://shoordaird.com/401/8008192',
        'https://soathoth.com/400/8008190',
        'https://thubanoa.com/1?z=8008191'
      ];

      for (const src of scripts) {
        try {
          await loadScript(src);
          console.log(`Script loaded successfully: ${src}`);
        } catch (error) {
          console.error(`Error loading script ${src}:`, error);
          if (retryCount < maxRetries) {
            retryCount++;
            console.log(`Retrying in ${retryDelay / 1000} seconds... (Attempt ${retryCount}/${maxRetries})`);
            await new Promise(resolve => setTimeout(resolve, retryDelay));
            continue;
          } else {
            console.error(`Failed to load script ${src} after ${maxRetries} attempts`);
          }
        }
      }

      setAdStatus('loaded');
    };

    loadAds();

    return () => {
      // Cleanup logic if necessary
    };
  }, [loadScript]);

  if (adStatus === 'error') {
    return (
      <div className="w-full bg-red-100 p-2 mt-4 text-red-700">
        Impossibile caricare gli annunci. Si prega di disabilitare il blocco degli annunci se attivo o riprovare più tardi.
      </div>
    );
  }

  return (
    <div className="w-full bg-gray-100 p-2 mt-4">
      <div className="flex flex-col sm:flex-row justify-between items-center">
        {adStatus === 'loading' ? (
          <div>Caricamento annunci...</div>
        ) : (
          <>
            <div id="container-1"></div>
            <div id="container-2"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default Advertisements;