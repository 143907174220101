const unitTranslations = {
    it: {
      'yottamole': 'yottamole',
      'zettamole': 'zettamole',
      'examole': 'examole',
      'petamole': 'petamole',
      'teramole': 'teramole',
      'gigamole': 'gigamole',
      'megamole': 'megamole',
      'kilomole': 'chilomole',
      'hectomole': 'ettomole',
      'decamole': 'decamole',
      'mole': 'mole',
      'decimole': 'decimole',
      'centimole': 'centimole',
      'millimole': 'millimole',
      'micromole': 'micromole',
      'nanomole': 'nanomole',
      'picomole': 'picomole',
      'femtomole': 'femtomole',
      'attomole': 'attomole',
      'zeptomole': 'zeptomole',
      'yoctomole': 'yoctomole',
      'mole-grammo': 'mole-grammo',
      'mole-kilogrammo': 'mole-chilogrammo',
      'mole-libbra': 'mole-libbra',
      'square-meter': 'metro quadrato',
      'square-yottameter': 'yottametro quadrato',
      'square-zettameter': 'zettametro quadrato',
      'square-exameter': 'exametro quadrato',
      'square-petameter': 'petametro quadrato',
      'square-terameter': 'terametro quadrato',
      'square-gigameter': 'gigametro quadrato',
      'square-megameter': 'megametro quadrato',
      'square-kilometer': 'chilometro quadrato',
      'square-hectometer': 'ettometro quadrato',
      'square-decameter': 'decametro quadrato',
      'square-decimeter': 'decimetro quadrato',
      'square-centimeter': 'centimetro quadrato',
      'square-millimeter': 'millimetro quadrato',
      'square-micrometer': 'micrometro quadrato',
      'square-nanometer': 'nanometro quadrato',
      'square-picometer': 'picometro quadrato',
      'square-femtometer': 'femtometro quadrato',
      'square-attometer': 'attometro quadrato',
      'square-zeptometer': 'zeptometro quadrato',
      'square-yoctometer': 'yoctometro quadrato',
      'square-foot': 'piede quadrato',
      'square-inch': 'pollice quadrato',
      'square-yard': 'iarda quadrata',
      'square-mile': 'miglio quadrato',
      'acre': 'acro',
      'hectare': 'ettaro',
          // Unità di corrente
    'ampere': 'ampere',
    'yottaampere': 'yottaampere',
    'zettaampere': 'zettaampere',
    'exaampere': 'exaampere',
    'petaampere': 'petaampere',
    'teraampere': 'teraampere',
    'gigaampere': 'gigaampere',
    'megaampere': 'megaampere',
    'kiloampere': 'chilampere',
    'hectoampere': 'ettoampere',
    'decaampere': 'decaampere',
    'deciampere': 'deciampere',
    'centiampere': 'centiampere',
    'milliampere': 'milliampere',
    'microampere': 'microampere',
    'nanoampere': 'nanoampere',
    'picoampere': 'picoampere',
    'femtoampere': 'femtoampere',
    'attoampere': 'attoampere',
    'zeptoampere': 'zeptoampere',
    'yoctoampere': 'yoctoampere',

    // Unità di densità
    'kilogram/cubic-meter': 'chilogrammo al metro cubo (kg/m³)',
    'hectogram/cubic-meter': 'ettogrammo al metro cubo (hg/m³)',
    'gram/cubic-meter': 'grammo al metro cubo (g/m³)',
    'milligram/cubic-meter': 'milligrammo al metro cubo (mg/m³)',
    'microgram/cubic-meter': 'microgrammo al metro cubo (µg/m³)',
    'nanogram/cubic-meter': 'nanogrammo al metro cubo (ng/m³)',
    'picogram/cubic-meter': 'picogrammo al metro cubo (pg/m³)',
    'femtogram/cubic-meter': 'femtogrammo al metro cubo (fg/m³)',
    'attogram/cubic-meter': 'attogrammo al metro cubo (ag/m³)',
    'megagram/cubic-meter': 'megagrammo al metro cubo (Mg/m³)',
    'teragram/cubic-meter': 'teragrammo al metro cubo (Tg/m³)',
    'kilogram/cubic-centimeter': 'chilogrammo al centimetro cubo (kg/cm³)',
    'hectogram/cubic-centimeter': 'ettogrammo al centimetro cubo (hg/cm³)',
    'gram/cubic-centimeter': 'grammo al centimetro cubo (g/cm³)',
    'milligram/cubic-centimeter': 'milligrammo al centimetro cubo (mg/cm³)',
    'microgram/cubic-centimeter': 'microgrammo al centimetro cubo (µg/cm³)',
    'nanogram/cubic-centimeter': 'nanogrammo al centimetro cubo (ng/cm³)',
    'picogram/cubic-centimeter': 'picogrammo al centimetro cubo (pg/cm³)',
    'femtogram/cubic-centimeter': 'femtogrammo al centimetro cubo (fg/cm³)',
    'attogram/cubic-centimeter': 'attogrammo al centimetro cubo (ag/cm³)',
    'megagram/cubic-centimeter': 'megagrammo al centimetro cubo (Mg/cm³)',
    'teragram/cubic-centimeter': 'teragrammo al centimetro cubo (Tg/cm³)',
    'kilogram/cubic-millimeter': 'chilogrammo al millimetro cubo (kg/mm³)',
    'hectogram/cubic-millimeter': 'ettogrammo al millimetro cubo (hg/mm³)',
    'gram/cubic-millimeter': 'grammo al millimetro cubo (g/mm³)',
    'milligram/cubic-millimeter': 'milligrammo al millimetro cubo (mg/mm³)',
    'microgram/cubic-millimeter': 'microgrammo al millimetro cubo (µg/mm³)',
    'nanogram/cubic-millimeter': 'nanogrammo al millimetro cubo (ng/mm³)',
    'picogram/cubic-millimeter': 'picogrammo al millimetro cubo (pg/mm³)',
    'femtogram/cubic-millimeter': 'femtogrammo al millimetro cubo (fg/mm³)',
    'attogram/cubic-millimeter': 'attogrammo al millimetro cubo (ag/mm³)',
    'megagram/cubic-millimeter': 'megagrammo al millimetro cubo (Mg/mm³)',
    'teragram/cubic-millimeter': 'teragrammo al millimetro cubo (Tg/mm³)',
    'kilogram/liter': 'chilogrammo al litro (kg/L)',
    'hectogram/liter': 'ettogrammo al litro (hg/L)',
    'gram/liter': 'grammo al litro (g/L)',
    'milligram/liter': 'milligrammo al litro (mg/L)',
    'microgram/liter': 'microgrammo al litro (µg/L)',
    'nanogram/liter': 'nanogrammo al litro (ng/L)',
    'picogram/liter': 'picogrammo al litro (pg/L)',
    'femtogram/liter': 'femtogrammo al litro (fg/L)',
    'attogram/liter': 'attogrammo al litro (ag/L)',
    'megagram/liter': 'megagrammo al litro (Mg/L)',
    'teragram/liter': 'teragrammo al litro (Tg/L)',
    'gram/milliliter': 'grammo al millilitro (g/mL)',
    'milligram/milliliter': 'milligrammo al millilitro (mg/mL)',
    'microgram/milliliter': 'microgrammo al millilitro (µg/mL)',
    'nanogram/milliliter': 'nanogrammo al millilitro (ng/mL)',
    'picogram/milliliter': 'picogrammo al millilitro (pg/mL)',
    'femtogram/milliliter': 'femtogrammo al millilitro (fg/mL)',
    'attogram/milliliter': 'attogrammo al millilitro (ag/mL)',
    'megagram/milliliter': 'megagrammo al millilitro (Mg/mL)',
    'teragram/milliliter': 'teragrammo al millilitro (Tg/mL)',
    'pound/cubic-foot': 'libbra al piede cubo (lb/ft³)',
    'ounce/cubic-foot': 'oncia al piede cubo (oz/ft³)',
    'pound/cubic-inch': 'libbra al pollice cubo (lb/in³)',
    'ounce/cubic-inch': 'oncia al pollice cubo (oz/in³)',
    'pound/gallon': 'libbra al gallone (lb/gal)',
    'ounce/gallon': 'oncia al gallone (oz/gal)',
    'pound/quart': 'libbra al quarto (lb/qt)',
    'ounce/quart': 'oncia al quarto (oz/qt)',
    // Unita di lunghezza
    'meter': 'metro',
    'kilometer': 'chilometro',
    'hectometer': 'ettometro',
    'decameter': 'decametro',
    'centimeter': 'centimetro',
    'millimeter': 'millimetro',
    'micrometer': 'micrometro',
    'nanometer': 'nanometro',
    'picometer': 'picometro',
    'femtometer': 'femtometro',
    'attometer': 'attometro',
    'zeptometer': 'zeptometro',
    'yoctometer': 'yoctometro',
    'mile': 'miglio',
    'yard': 'iarda',
    'foot': 'piede',
    'inch': 'pollice',
    'nautical mile': 'miglio nautico',
    'angstrom': 'angstrom',
    'yottameter': 'yottametro',
    'zettameter': 'zettametro',
    'exameter': 'exametro',
    'petameter': 'petametro',
    'terameter': 'terametro',
    'gigameter': 'gigametro',
    'megameter': 'megametro',

    // Unità di intensità luminosa
    'candela': 'candela',
    'yottacandela': 'yottacandela',
    'zettacandela': 'zettacandela',
    'exacandela': 'esacandela',
    'petacandela': 'petacandela',
    'teracandela': 'teracandela',
    'gigacandela': 'gigacandela',
    'megacandela': 'megacandela',
    'kilocandela': 'chilocandela',
    'hectocandela': 'ettocandela',
    'decacandela': 'decacandela',
    'decicandela': 'decicandela',
    'centicandela': 'centicandela',
    'millicandela': 'millicandela',
    'microcandela': 'microcandela',
    'nanocandela': 'nanocandela',
    'picocandela': 'picocandela',
    'femtocandela': 'femtocandela',
    'attocandela': 'attocandela',
    'zeptocandela': 'zeptocandela',
    'yoctocandela': 'yoctocandela',
    'candlepower': 'candela internazionale',
    'hefnerUnit': 'unità Hefner',
    'carcelUnit': 'unità Carcel',
    'bougieDecimal': 'bougie Decimale',

    // Unità di massa
    'kilogram': 'chilogrammo',
    'gram': 'grammo',
    'tonnellata': 'tonnellata',
    'libbra': 'libbra',
    'oncia': 'oncia',
    'carato': 'carato',
    'stone': 'stone',
    'yottagram': 'yottagrammo',
    'zettagram': 'zettagrammo',
    'exagram': 'exagrammo',
    'petagram': 'petagrammo',
    'teragram': 'teragrammo',
    'gigagram': 'gigagrammo',
    'megagram': 'megagrammo',
    'hectogram': 'ettogrammo',
    'decagram': 'decagrammo',
    'decigram': 'decigrammo',
    'centigram': 'centigrammo',
    'milligram': 'milligrammo',
    'microgram': 'microgrammo',
    'nanogram': 'nanogrammo',
    'picogram': 'picogrammo',
    'femtogram': 'femtogrammo',
    'attogram': 'attogrammo',
    'zeptogram': 'zeptogrammo',
    'yoctogram': 'yoctogrammo',

    // Unità di temperatura
    'Kelvin': 'Kelvin',
    'Celsius': 'Celsius',
    'Fahrenheit': 'Fahrenheit',
    'Rankine': 'Rankine',
    'Reaumur': 'Réaumur',

    // Unità di tempo
    'seconds': 'secondi',
    'minutes': 'minuti',
    'hours': 'ore',
    'days': 'giorni',
    'weeks': 'settimane',
    'months': 'mesi',
    'years': 'anni',
    'yottaseconds': 'yottasecondi',
    'zettaseconds': 'zettasecondi',
    'exaseconds': 'esasecondi',
    'petaseconds': 'petasecondi',
    'teraseconds': 'terasecondi',
    'gigaseconds': 'gigasecondi',
    'megaseconds': 'megasecondi',
    'kiloseconds': 'chilosecondi',
    'hectoseconds': 'ettosecondi',
    'decaseconds': 'decasecondi',
    'deciseconds': 'decisecondi',
    'centiseconds': 'centisecondi',
    'milliseconds': 'millisecondi',
    'microseconds': 'microsecondi',
    'nanoseconds': 'nanosecondi',
    'picoseconds': 'picosecondi',
    'femtoseconds': 'femtosecondi',
    'attoseconds': 'attosecondi',
    'zeptoseconds': 'zeptosecondi',
    'yoctoseconds': 'yoctosecondi',
    'cubic-meter': 'metro cubo',
    'cubic-yottameter': 'yottametro cubo',
    'cubic-zettameter': 'zettametro cubo',
    'cubic-exameter': 'esametro cubo',
    'cubic-petameter': 'petametro cubo',
    'cubic-terameter': 'terametro cubo',
    'cubic-gigameter': 'gigametro cubo',
    'cubic-megameter': 'megametro cubo',
    'cubic-kilometer': 'chilometro cubo',
    'cubic-hectometer': 'ettometro cubo',
    'cubic-decameter': 'decametro cubo',
    'cubic-decimeter': 'decimetro cubo',
    'cubic-centimeter': 'centimetro cubo',
    'cubic-millimeter': 'millimetro cubo',
    'cubic-micrometer': 'micrometro cubo',
    'cubic-nanometer': 'nanometro cubo',
    'cubic-picometer': 'picometro cubo',
    'cubic-femtometer': 'femtometro cubo',
    'cubic-attometer': 'attometro cubo',
    'cubic-zeptometer': 'zeptometro cubo',
    'cubic-yoctometer': 'yoctometro cubo',
    'liter': 'litro',
    'milliliter': 'millilitro',
    'gallon': 'gallone',
    'quart': 'quarto',
    'pint': 'pinta',
    'cup': 'tazza',
    'fluid-ounce': 'oncia fluida',
    'tablespoon': 'cucchiaio',
    'teaspoon': 'cucchiaino',
    'cubic-inch': 'pollice cubo',
    'cubic-foot': 'piede cubo',
    'cubic-yard': 'iarda cubica',

    
    },
    
    en: {
      'yottamole': 'yottamole',
      'zettamole': 'zettamole',
      'examole': 'examole',
      'petamole': 'petamole',
      'teramole': 'teramole',
      'gigamole': 'gigamole',
      'megamole': 'megamole',
      'kilomole': 'kilomole',
      'hectomole': 'hectomole',
      'decamole': 'decamole',
      'mole': 'mole',
      'decimole': 'decimole',
      'centimole': 'centimole',
      'millimole': 'millimole',
      'micromole': 'micromole',
      'nanomole': 'nanomole',
      'picomole': 'picomole',
      'femtomole': 'femtomole',
      'attomole': 'attomole',
      'zeptomole': 'zeptomole',
      'yoctomole': 'yoctomole',
      'mole-grammo': 'gram-mole',
      'mole-kilogrammo': 'kilogram-mole',
      'mole-libbra': 'pound-mole',
      'square-meter': 'square meter',
      'square-yottameter': 'square yottameter',
      'square-zettameter': 'square zettameter',
      'square-exameter': 'square exameter',
      'square-petameter': 'square petameter',
      'square-terameter': 'square terameter',
      'square-gigameter': 'square gigameter',
      'square-megameter': 'square megameter',
      'square-kilometer': 'square kilometer',
      'square-hectometer': 'square hectometer',
      'square-decameter': 'square decameter',
      'square-decimeter': 'square decimeter',
      'square-centimeter': 'square centimeter',
      'square-millimeter': 'square millimeter',
      'square-micrometer': 'square micrometer',
      'square-nanometer': 'square nanometer',
      'square-picometer': 'square picometer',
      'square-femtometer': 'square femtometer',
      'square-attometer': 'square attometer',
      'square-zeptometer': 'square zeptometer',
      'square-yoctometer': 'square yoctometer',
      'square-foot': 'square foot',
      'square-inch': 'square inch',
      'square-yard': 'square yard',
      'square-mile': 'square mile',
      'acre': 'acre',
      'hectare': 'hectare',
       // Unità di corrente
    'ampere': 'ampere',
    'yottaampere': 'yottaampere',
    'zettaampere': 'zettaampere',
    'exaampere': 'exaampere',
    'petaampere': 'petaampere',
    'teraampere': 'teraampere',
    'gigaampere': 'gigaampere',
    'megaampere': 'megaampere',
    'kiloampere': 'kiloampere',
    'hectoampere': 'hectoampere',
    'decaampere': 'decaampere',
    'deciampere': 'deciampere',
    'centiampere': 'centiampere',
    'milliampere': 'milliampere',
    'microampere': 'microampere',
    'nanoampere': 'nanoampere',
    'picoampere': 'picoampere',
    'femtoampere': 'femtoampere',
    'attoampere': 'attoampere',
    'zeptoampere': 'zeptoampere',
    'yoctoampere': 'yoctoampere',

    // Unità di densità
    'kilogram/cubic-meter': 'kilogram per cubic meter (kg/m³)',
    'hectogram/cubic-meter': 'hectogram per cubic meter (hg/m³)',
    'gram/cubic-meter': 'gram per cubic meter (g/m³)',
    'milligram/cubic-meter': 'milligram per cubic meter (mg/m³)',
    'microgram/cubic-meter': 'microgram per cubic meter (µg/m³)',
    'nanogram/cubic-meter': 'nanogram per cubic meter (ng/m³)',
    'picogram/cubic-meter': 'picogram per cubic meter (pg/m³)',
    'femtogram/cubic-meter': 'femtogram per cubic meter (fg/m³)',
    'attogram/cubic-meter': 'attogram per cubic meter (ag/m³)',
    'megagram/cubic-meter': 'megagram per cubic meter (Mg/m³)',
    'teragram/cubic-meter': 'teragram per cubic meter (Tg/m³)',
    'kilogram/cubic-centimeter': 'kilogram per cubic centimeter (kg/cm³)',
    'hectogram/cubic-centimeter': 'hectogram per cubic centimeter (hg/cm³)',
    'gram/cubic-centimeter': 'gram per cubic centimeter (g/cm³)',
    'milligram/cubic-centimeter': 'milligram per cubic centimeter (mg/cm³)',
    'microgram/cubic-centimeter': 'microgram per cubic centimeter (µg/cm³)',
    'nanogram/cubic-centimeter': 'nanogram per cubic centimeter (ng/cm³)',
    'picogram/cubic-centimeter': 'picogram per cubic centimeter (pg/cm³)',
    'femtogram/cubic-centimeter': 'femtogram per cubic centimeter (fg/cm³)',
    'attogram/cubic-centimeter': 'attogram per cubic centimeter (ag/cm³)',
    'megagram/cubic-centimeter': 'megagram per cubic centimeter (Mg/cm³)',
    'teragram/cubic-centimeter': 'teragram per cubic centimeter (Tg/cm³)',
    'kilogram/cubic-millimeter': 'kilogram per cubic millimeter (kg/mm³)',
    'hectogram/cubic-millimeter': 'hectogram per cubic millimeter (hg/mm³)',
    'gram/cubic-millimeter': 'gram per cubic millimeter (g/mm³)',
    'milligram/cubic-millimeter': 'milligram per cubic millimeter (mg/mm³)',
    'microgram/cubic-millimeter': 'microgram per cubic millimeter (µg/mm³)',
    'nanogram/cubic-millimeter': 'nanogram per cubic millimeter (ng/mm³)',
    'picogram/cubic-millimeter': 'picogram per cubic millimeter (pg/mm³)',
    'femtogram/cubic-millimeter': 'femtogram per cubic millimeter (fg/mm³)',
    'attogram/cubic-millimeter': 'attogram per cubic millimeter (ag/mm³)',
    'megagram/cubic-millimeter': 'megagram per cubic millimeter (Mg/mm³)',
    'teragram/cubic-millimeter': 'teragram per cubic millimeter (Tg/mm³)',
    'kilogram/liter': 'kilogram per liter (kg/L)',
    'hectogram/liter': 'hectogram per liter (hg/L)',
    'gram/liter': 'gram per liter (g/L)',
    'milligram/liter': 'milligram per liter (mg/L)',
    'microgram/liter': 'microgram per liter (µg/L)',
    'nanogram/liter': 'nanogram per liter (ng/L)',
    'picogram/liter': 'picogram per liter (pg/L)',
    'femtogram/liter': 'femtogram per liter (fg/L)',
    'attogram/liter': 'attogram per liter (ag/L)',
    'megagram/liter': 'megagram per liter (Mg/L)',
    'teragram/liter': 'teragram per liter (Tg/L)',
    'gram/milliliter': 'gram per milliliter (g/mL)',
    'milligram/milliliter': 'milligram per milliliter (mg/mL)',
    'microgram/milliliter': 'microgram per milliliter (µg/mL)',
    'nanogram/milliliter': 'nanogram per milliliter (ng/mL)',
    'picogram/milliliter': 'picogram per milliliter (pg/mL)',
    'femtogram/milliliter': 'femtogram per milliliter (fg/mL)',
    'attogram/milliliter': 'attogram per milliliter (ag/mL)',
    'megagram/milliliter': 'megagram per milliliter (Mg/mL)',
    'teragram/milliliter': 'teragram per milliliter (Tg/mL)',
    'pound/cubic-foot': 'pound per cubic foot (lb/ft³)',
    'ounce/cubic-foot': 'ounce per cubic foot (oz/ft³)',
    'pound/cubic-inch': 'pound per cubic inch (lb/in³)',
    'ounce/cubic-inch': 'ounce per cubic inch (oz/in³)',
    'pound/gallon': 'pound per gallon (lb/gal)',
    'ounce/gallon': 'ounce per gallon (oz/gal)',
    'pound/quart': 'pound per quart (lb/qt)',
    'ounce/quart': 'ounce per quart (oz/qt)',
    //unita di lunghezza
    'meter': 'meter',
    'kilometer': 'kilometer',
    'hectometer': 'hectometer',
    'decameter': 'decameter',
    'centimeter': 'centimeter',
    'millimeter': 'millimeter',
    'micrometer': 'micrometer',
    'nanometer': 'nanometer',
    'picometer': 'picometer',
    'femtometer': 'femtometer',
    'attometer': 'attometer',
    'zeptometer': 'zeptometer',
    'yoctometer': 'yoctometer',
    'mile': 'mile',
    'yard': 'yard',
    'foot': 'foot',
    'inch': 'inch',
    'nautical mile': 'nautical mile',
    'angstrom': 'angstrom',
    'yottameter': 'yottameter',
    'zettameter': 'zettameter',
    'exameter': 'exameter',
    'petameter': 'petameter',
    'terameter': 'terameter',
    'gigameter': 'gigameter',
    'megameter': 'megameter',

    // Unità di intensità luminosa
    'candela': 'candela',
    'yottacandela': 'yottacandela',
    'zettacandela': 'zettacandela',
    'exacandela': 'exacandela',
    'petacandela': 'petacandela',
    'teracandela': 'teracandela',
    'gigacandela': 'gigacandela',
    'megacandela': 'megacandela',
    'kilocandela': 'kilocandela',
    'hectocandela': 'hectocandela',
    'decacandela': 'decacandela',
    'decicandela': 'decicandela',
    'centicandela': 'centicandela',
    'millicandela': 'millicandela',
    'microcandela': 'microcandela',
    'nanocandela': 'nanocandela',
    'picocandela': 'picocandela',
    'femtocandela': 'femtocandela',
    'attocandela': 'attocandela',
    'zeptocandela': 'zeptocandela',
    'yoctocandela': 'yoctocandela',
    'candlepower': 'international candle',
    'hefnerUnit': 'Hefner unit',
    'carcelUnit': 'Carcel unit',
    'bougieDecimal': 'Decimal candle',

    // Unità di massa
    'kilogram': 'kilogram',
    'gram': 'gram',
    'tonnellata': 'ton',
    'libbra': 'pound',
    'oncia': 'ounce',
    'carato': 'carat',
    'stone': 'stone',
    'yottagram': 'yottagram',
    'zettagram': 'zettagram',
    'exagram': 'exagram',
    'petagram': 'petagram',
    'teragram': 'teragram',
    'gigagram': 'gigagram',
    'megagram': 'megagram',
    'hectogram': 'hectogram',
    'decagram': 'decagram',
    'decigram': 'decigram',
    'centigram': 'centigram',
    'milligram': 'milligram',
    'microgram': 'microgram',
    'nanogram': 'nanogram',
    'picogram': 'picogram',
    'femtogram': 'femtogram',
    'attogram': 'attogram',
    'zeptogram': 'zeptogram',
    'yoctogram': 'yoctogram',

    // Unità di temperatura
    'Kelvin': 'Kelvin',
    'Celsius': 'Celsius',
    'Fahrenheit': 'Fahrenheit',
    'Rankine': 'Rankine',
    'Reaumur': 'Réaumur',

    // Unità di tempo
    'seconds': 'seconds',
    'minutes': 'minutes',
    'hours': 'hours',
    'days': 'days',
    'weeks': 'weeks',
    'months': 'months',
    'years': 'years',
    'yottaseconds': 'yottaseconds',
    'zettaseconds': 'zettaseconds',
    'exaseconds': 'exaseconds',
    'petaseconds': 'petaseconds',
    'teraseconds': 'teraseconds',
    'gigaseconds': 'gigaseconds',
    'megaseconds': 'megaseconds',
    'kiloseconds': 'kiloseconds',
    'hectoseconds': 'hectoseconds',
    'decaseconds': 'decaseconds',
    'deciseconds': 'deciseconds',
    'centiseconds': 'centiseconds',
    'milliseconds': 'milliseconds',
    'microseconds': 'microseconds',
    'nanoseconds': 'nanoseconds',
    'picoseconds': 'picoseconds',
    'femtoseconds': 'femtoseconds',
    'attoseconds': 'attoseconds',
    'zeptoseconds': 'zeptoseconds',
    'yoctoseconds': 'yoctoseconds',
    'cubic-meter': 'cubic meter',
    'cubic-yottameter': 'cubic yottameter',
    'cubic-zettameter': 'cubic zettameter',
    'cubic-exameter': 'cubic exameter',
    'cubic-petameter': 'cubic petameter',
    'cubic-terameter': 'cubic terameter',
    'cubic-gigameter': 'cubic gigameter',
    'cubic-megameter': 'cubic megameter',
    'cubic-kilometer': 'cubic kilometer',
    'cubic-hectometer': 'cubic hectometer',
    'cubic-decameter': 'cubic decameter',
    'cubic-decimeter': 'cubic decimeter',
    'cubic-centimeter': 'cubic centimeter',
    'cubic-millimeter': 'cubic millimeter',
    'cubic-micrometer': 'cubic micrometer',
    'cubic-nanometer': 'cubic nanometer',
    'cubic-picometer': 'cubic picometer',
    'cubic-femtometer': 'cubic femtometer',
    'cubic-attometer': 'cubic attometer',
    'cubic-zeptometer': 'cubic zeptometer',
    'cubic-yoctometer': 'cubic yoctometer',
    'liter': 'liter',
    'milliliter': 'milliliter',
    'gallon': 'gallon',
    'quart': 'quart',
    'pint': 'pint',
    'cup': 'cup',
    'fluid-ounce': 'fluid ounce',
    'tablespoon': 'tablespoon',
    'teaspoon': 'teaspoon',
    'cubic-inch': 'cubic inch',
    'cubic-foot': 'cubic foot',
    'cubic-yard': 'cubic yard',

  }
};

export function translateUnit(unit, language = 'it') {
  if (unitTranslations[language] && unitTranslations[language][unit]) {
    return unitTranslations[language][unit];
  }
  return unit; // Ritorna l'unità originale se non trova una traduzione
}

export function getAvailableLanguages() {
  return Object.keys(unitTranslations);
}