import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('aboutUs.metaTitle')}</title>
        <meta name="description" content={t('aboutUs.metaDescription')} />
        <meta property="og:title" content={t('aboutUs.metaTitle')} />
        <meta property="og:description" content={t('aboutUs.metaDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.uniconverter.online/${i18n.language}/about`} />
        <link rel="canonical" href={`https://www.uniconverter.online/${i18n.language}/about`} />
      </Helmet>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">{t('aboutUs.title')}</h1>
        <p className="mb-4">
          {t('aboutUs.description')}
        </p>
        <h2 className="text-xl font-semibold mb-3">{t('aboutUs.uniqueness.title')}</h2>
        <ul className="list-disc list-inside mb-4">
          <li className="mb-2">
            <strong>{t('aboutUs.uniqueness.siPrefixes.title')}:</strong> {t('aboutUs.uniqueness.siPrefixes.description')}
          </li>
          <li className="mb-2">
            <strong>{t('aboutUs.uniqueness.nonSiUnits.title')}:</strong> {t('aboutUs.uniqueness.nonSiUnits.description')}
          </li>
          <li className="mb-2">
            <strong>{t('aboutUs.uniqueness.precision.title')}:</strong> {t('aboutUs.uniqueness.precision.description')}
          </li>
          <li className="mb-2">
            <strong>{t('aboutUs.uniqueness.interface.title')}:</strong> {t('aboutUs.uniqueness.interface.description')}
          </li>
        </ul>
        <p className="mb-4">
          {t('aboutUs.mission')}
        </p>
        <p>
          {t('aboutUs.feedback')}
        </p>
      </div>
    </>
  );
};

export default AboutUs;