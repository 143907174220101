import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';
// eslint-disable-next-line no-unused-vars
import i18n from './i18n';
import UnitConverter from './components/UnitConverter';
import ConversionPage from './components/ConversionPage';
import Advertisements from './components/Advertisements';
import TimeConversion from './components/TimeConversion';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';

// Inizializza Google Analytics
ReactGA.initialize('G-V9VP1QBQ54');

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    ReactGA.event({
      category: 'User',
      action: 'Changed Language',
      label: event.target.value
    });
  };

  return (
    <select
      onChange={changeLanguage}
      value={i18n.language}
      className="text-sm p-1 border rounded bg-white"
    >
      <option value="it">Italiano</option>
      <option value="en">English</option>
    </select>
  );
}

function Navigation() {
  const { t } = useTranslation();

  const handleNavClick = (pageName) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Nav Link',
      label: pageName
    });
  };

  return (
    <nav>
      <ul className="flex space-x-6">
        <li><Link to="/" onClick={() => handleNavClick('Home')} className="text-gray-800 font-semibold hover:text-gray-900 hover:underline transition duration-300">{t('navigation.home')}</Link></li>
        <li><Link to="/about" onClick={() => handleNavClick('About')} className="text-gray-800 font-semibold hover:text-gray-900 hover:underline transition duration-300">{t('navigation.aboutUs')}</Link></li>
        <li><Link to="/contact" onClick={() => handleNavClick('Contact')} className="text-gray-800 font-semibold hover:text-gray-900 hover:underline transition duration-300">{t('navigation.contact')}</Link></li>
        <li><Link to="/privacy" onClick={() => handleNavClick('Privacy')} className="text-gray-800 font-semibold hover:text-gray-900 hover:underline transition duration-300">{t('navigation.privacy')}</Link></li>
      </ul>
    </nav>
  );
}

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then(registration => {
          console.log('SW registered: ', registration);
          if ('periodicSync' in registration) {
            registration.periodicSync.register('update-cache', {
              minInterval: 24 * 60 * 60 * 1000,
            }).then(() => {
              console.log('Periodic Sync registered');
            }).catch(error => {
              console.error('Periodic Sync could not be registered!', error);
            });
          }
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
        <Helmet>
          <html lang={i18n.language} />
          <title>{t('app.metaTitle')}</title>
        <meta name="description" content={t('app.metaDescription')} />
          <link rel="canonical" href={`https://www.uniconverter.online${location.pathname}`} />
          <meta property="og:title" content={t('app.title')} />
          <meta property="og:description" content={t('app.description')} />
          <meta property="og:url" content={`https://www.uniconverter.online${location.pathname}`} />
          <meta property="og:type" content="website" />
          <link rel="alternate" hreflang="it" href={`https://www.uniconverter.online${location.pathname}`} />
          <link rel="alternate" hreflang="en" href={`https://www.uniconverter.online${location.pathname}`} />
          <link rel="alternate" hreflang="x-default" href={`https://www.uniconverter.online${location.pathname}`} />
        </Helmet>
        <header className="bg-gray-100 p-4">
          <div className="container mx-auto flex justify-between items-center">
            <Navigation />
            <LanguageSelector />
          </div>
        </header>
        <main className="container mx-auto mt-8 p-4 flex-grow">
          <h1 className="text-4xl font-bold text-center text-purple-600 mb-8">{t('title')}</h1>
          <Routes>
            <Route path="/" element={<UnitConverter />} />
            <Route path="/convert/:quantity" element={<ConversionPage />} />
            <Route path="/time-conversion" element={<TimeConversion key={i18n.language} />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Advertisements />
        <footer className="bg-[#CCCCCC] p-4 mt-8">
          <div className="container mx-auto flex justify-between items-center">
            <p className="text-gray-800">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
            <Link to="/privacy" className="text-gray-700 hover:text-gray-900 transition duration-300">
              {t('footer.privacyPolicy')}
            </Link>
          </div>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText={t('cookieConsent.accept')}
          cookieName="myWebsiteCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          onAccept={() => {
            ReactGA.event({
              category: 'User',
              action: 'Accepted Cookies'
            });
          }}
        >
          {t('cookieConsent.message')}{" "}
          <span style={{ fontSize: "10px" }}>
            {t('cookieConsent.learnMore')}{" "}
            <Link to="/privacy">{t('cookieConsent.privacyPolicy')}</Link>.
          </span>
        </CookieConsent>
      </div>
    </I18nextProvider>
  );
}

export default App;