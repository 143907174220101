import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('privacyPolicy.metaTitle')}</title>
        <meta name="description" content={t('privacyPolicy.metaDescription')} />
        <meta property="og:title" content={t('privacyPolicy.metaTitle')} />
        <meta property="og:description" content={t('privacyPolicy.metaDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.uniconverter.online/${i18n.language}/privacy-policy`} />
        <link rel="canonical" href={`https://www.uniconverter.online/${i18n.language}/privacy-policy`} />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{t('privacyPolicy.title')}</h1>
        <p className="mb-4">{t('privacyPolicy.lastUpdated', { date: new Date().toLocaleDateString() })}</p>
        
        <article>
          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.introduction.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.introduction.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.informationCollected.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.informationCollected.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.googleAnalytics.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.googleAnalytics.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.informationUse.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.informationUse.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.cookies.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.cookies.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.changes.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.changes.content')}</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.contact.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.contact.content')}</p>
          </section>
          
          <section>
            <h2 className="text-2xl font-semibold mt-6 mb-4">{t('privacyPolicy.optOut.title')}</h2>
            <p className="mb-4">{t('privacyPolicy.optOut.content')}</p>
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              {t('privacyPolicy.optOut.link')}
            </a>
          </section>
        </article>
      </div>
    </>
  );
}

export default PrivacyPolicy;