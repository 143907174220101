import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import * as conversionFunctions from '../utils/conversions';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import DensityConversion from './DensityConversion';
import StandardTimeConversion from './StandardTimeConversion';
import CompositeTimeConversion from './CompositeTimeConversion';
import { translateUnit } from '../utils/conversions/UnitTranslations';

const ConversionPage = () => {
  const { quantity } = useParams();
  const { t, i18n } = useTranslation();
  const [fromValue, setFromValue] = useState('');
  const [fromUnit, setFromUnit] = useState('');
  const [toUnit, setToUnit] = useState('');
  const [result, setResult] = useState('');
  const [scientificNotation, setScientificNotation] = useState('');
  const [units, setUnits] = useState([]);
  const [error, setError] = useState('');
  const [conversionHistory, setConversionHistory] = useState([]);

  // Funzione per ottenere il titolo della pagina
  const getPageTitle = () => {
    return t(`${quantity}Conversion.metaTitle`, { 
      defaultValue: t('conversionPage.metaTitle', { quantity: t(quantity) })
    });
  };

  // Funzione per ottenere la descrizione della pagina
  const getPageDescription = () => {
    return t(`${quantity}Conversion.metaDescription`, { 
      defaultValue: t('conversionPage.metaDescription', { quantity: t(quantity) })
    });
  };

  useEffect(() => {
    const quantityCapitalized = quantity.charAt(0).toUpperCase() + quantity.slice(1);
    console.log('Quantità:', quantityCapitalized);
    const quantityModule = conversionFunctions[quantityCapitalized];
    console.log('Modulo Quantità:', quantityModule);

    if (quantityModule) {
      console.log('Funzioni del modulo:', Object.keys(quantityModule));
      const availableUnits = quantityModule.units || [];
      console.log('Unità disponibili:', availableUnits);
      setUnits(availableUnits);
      if (availableUnits.length > 0) {
        setFromUnit(availableUnits[0].name);
        setToUnit(availableUnits[0].name);
        console.log('Unità iniziale da:', availableUnits[0].name);
        console.log('Unità iniziale a:', availableUnits[0].name);
      } else {
        console.error('Nessuna unità disponibile per', quantityCapitalized);
      }
    } else {
      console.error(`Modulo per ${quantityCapitalized} non trovato`);
      setError(t('moduleNotFound'));
    }
  }, [quantity, t]);

  const handleConvert = () => {
    console.log('handleConvert chiamato');
    console.log('Stato attuale:', { fromValue, fromUnit, toUnit });

    setError('');
    const value = parseFloat(fromValue);
    if (isNaN(value)) {
      setError(t('invalidNumberError'));
      setResult('');
      setScientificNotation('');
      return;
    }
    try {
      const quantityCapitalized = quantity.charAt(0).toUpperCase() + quantity.slice(1);
      const quantityModule = conversionFunctions[quantityCapitalized];
      
      console.log('Modulo quantità:', quantityModule);
      console.log('Funzione di conversione:', quantityModule.convertWithScientificNotation);
      
      const convertFunction = quantityModule.convertWithScientificNotation;
      if (typeof convertFunction !== 'function') {
        throw new Error(t('conversionNotSupported'));
      }
    
      console.log('Chiamata alla funzione di conversione con:', { value, fromUnit, toUnit });
      const result = convertFunction(value, fromUnit, toUnit);
    
      console.log('Risultato della conversione:', result);

      if (result && result.formattedValue) {
        const formattedResult = `${result.formattedValue} ${getUnitSymbol(toUnit)}`;
        setResult(formattedResult);
        
        let scientificNotationString = '';
        if (result.scientificNotation) {
          if (typeof result.scientificNotation === 'object') {
            scientificNotationString = `${result.scientificNotation.coefficient.toFixed(6)} × 10^${result.scientificNotation.exponent}`;
          } else {
            scientificNotationString = result.scientificNotation.toString();
          }
        }
        setScientificNotation(scientificNotationString);
        
        // Aggiungi alla cronologia delle conversioni
        setConversionHistory(prevHistory => [
          { from: `${value} ${getUnitSymbol(fromUnit)}`, to: formattedResult },
          ...prevHistory.slice(0, 4)
        ]);
      } else {
        throw new Error(t('conversionNotSupported'));
      }
    } catch (error) {
      console.error('Errore nella conversione:', error);
      setError(error.message || t('conversionNotSupported'));
      setResult('');
      setScientificNotation('');
    }
  };

  const getUnitSymbol = (unitName) => {
    const unit = units.find(u => u.name === unitName);
    return unit ? unit.symbol : unitName;
  };

  const renderUnitOptions = () => {
    console.log('Rendering opzioni unità. Unità:', units);
    return units.map((unit, index) => (
      <option key={`${unit.name}-${index}`} value={unit.name}>
        {translateUnit(unit.name, i18n.language)} ({unit.symbol})
      </option>
    ));
  };

  const renderConversionInterface = () => {
    switch (quantity.toLowerCase()) {
      case 'density':
        return <DensityConversion />;
      case 'time':
        return (
          <>
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">{t('standardTimeConversion')}</h2>
              <StandardTimeConversion />
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-4">{t('compositeTimeConversion')}</h2>
              <CompositeTimeConversion />
            </div>
          </>
        );
      default:
        return (
          <div className="space-y-4">
            <input
              type="number"
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder={t('enterValue')}
            />
            <div className="flex items-center space-x-2">
              <select
                value={fromUnit}
                onChange={(e) => setFromUnit(e.target.value)}
                className="w-full p-2 border rounded"
              >
                {renderUnitOptions()}
              </select>
              <select
                value={toUnit}
                onChange={(e) => setToUnit(e.target.value)}
                className="w-full p-2 border rounded"
              >
                {renderUnitOptions()}
              </select>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleConvert}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                {t('convert')}
              </button>
            </div>
            {error && (
              <div className="mt-4 p-2 bg-red-100 text-red-700 rounded">
                {error}
              </div>
            )}
            {result && (
              <div className="mt-4 p-2 bg-gray-100 rounded">
                <p>{t('result')}: {result}</p>
                {scientificNotation && <p>{t('scientificNotation')}: {scientificNotation}</p>}
              </div>
            )}

            {conversionHistory.length > 0 && (
              <div className="mt-4">
                <h2 className="text-lg font-semibold mb-2">{t('conversionHistory')}</h2>
                <ul className="space-y-2">
                  {conversionHistory.map((item, index) => (
                    <li key={index} className="p-2 bg-gray-50 rounded">
                      {item.from} → {item.to}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
        <meta property="og:title" content={getPageTitle()} />
        <meta property="og:description" content={getPageDescription()} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.uniconverter.online/${i18n.language}/convert/${quantity}`} />
        <link rel="canonical" href={`https://www.uniconverter.online/${i18n.language}/convert/${quantity}`} />
      </Helmet>
      <div className="p-4 max-w-2xl mx-auto">
        <Link to="/" className="text-purple-600 hover:text-purple-800 mb-4 block">&larr; {t('backToList')}</Link>
        <h1 className="text-2xl font-bold mb-4">{t('conversionTitle', { quantity: t(quantity) })}</h1>
        {renderConversionInterface()}
      </div>
    </>
  );
};

export default ConversionPage;