import React, { useState } from 'react';
import {
  convertDensityWithScientificNotation,
  siDensityUnits,
  imperialDensityUnits,
  getUnitSymbol
} from '../utils/conversions/density';
import { useTranslation } from 'react-i18next';
import { translateUnit } from '../utils/conversions/UnitTranslations';

const DensityConversion = () => {
  const { t, i18n } = useTranslation();
  const [siValue, setSIValue] = useState('');
  const [siFromUnit, setSIFromUnit] = useState('kilogram/cubic-meter');
  const [siToUnit, setSIToUnit] = useState('kilogram/cubic-meter');
  const [siResult, setSIResult] = useState(null);

  const [imperialValue, setImperialValue] = useState('');
  const [imperialFromUnit, setImperialFromUnit] = useState('kilogram/cubic-meter');
  const [imperialToUnit, setImperialToUnit] = useState('pound/cubic-foot');
  const [imperialResult, setImperialResult] = useState(null);

  const [conversionHistory, setConversionHistory] = useState([]);

  const handleSIConvert = () => {
    const numValue = parseFloat(siValue);
    if (isNaN(numValue)) {
      alert(t('invalidNumberError'));
      return;
    }
    const result = convertDensityWithScientificNotation(numValue, siFromUnit, siToUnit, 'SI');
    setSIResult(result);
    addToHistory(numValue, siFromUnit, result.formattedValue, siToUnit);
  };

  const handleImperialConvert = () => {
    const numValue = parseFloat(imperialValue);
    if (isNaN(numValue)) {
      alert(t('invalidNumberError'));
      return;
    }
    const result = convertDensityWithScientificNotation(numValue, imperialFromUnit, imperialToUnit, 'imperial');
    setImperialResult(result);
    addToHistory(numValue, imperialFromUnit, result.formattedValue, imperialToUnit);
  };

  const addToHistory = (fromValue, fromUnit, toValue, toUnit) => {
    const newEntry = {
      from: `${fromValue} ${getUnitSymbol(fromUnit)}`,
      to: `${toValue} ${getUnitSymbol(toUnit)}`
    };
    setConversionHistory(prevHistory => [newEntry, ...prevHistory.slice(0, 4)]);
  };

  const getTranslatedLabel = (unit) => {
    return translateUnit(unit.name, i18n.language) + ` (${unit.symbol})`;
  };

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">{t('densityConversionTitle')}</h2>
      
      {/* Sezione Unità SI */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">{t('siUnitsTitle')}</h3>
        <input
          type="number"
          value={siValue}
          onChange={(e) => setSIValue(e.target.value)}
          className="w-full p-2 border rounded mb-2"
          placeholder={t('enterValue')}
        />
        <div className="flex space-x-2 mb-2">
          <select
            value={siFromUnit}
            onChange={(e) => setSIFromUnit(e.target.value)}
            className="w-1/2 p-2 border rounded"
          >
            {siDensityUnits.map(unit => (
              <option key={unit.name} value={unit.name}>{getTranslatedLabel(unit)}</option>
            ))}
          </select>
          <select
            value={siToUnit}
            onChange={(e) => setSIToUnit(e.target.value)}
            className="w-1/2 p-2 border rounded"
          >
            {siDensityUnits.map(unit => (
              <option key={unit.name} value={unit.name}>{getTranslatedLabel(unit)}</option>
            ))}
          </select>
        </div>
        <button
          onClick={handleSIConvert}
          className="w-full p-2 bg-purple-500 text-white font-semibold py-3 px-6 rounded"
        >
          {t('convertSIUnits')}
        </button>
        {siResult && (
          <div className="mt-2 p-2 bg-gray-100 rounded">
            <p>{t('result')}: {siResult.formattedValue} {getUnitSymbol(siToUnit)}</p>
            <p>{t('scientificNotation')}: {siResult.scientificNotation} {getUnitSymbol(siToUnit)}</p>
          </div>
        )}
      </div>

      {/* Sezione Unità Imperiali */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">{t('imperialUnitsTitle')}</h3>
        <input
          type="number"
          value={imperialValue}
          onChange={(e) => setImperialValue(e.target.value)}
          className="w-full p-2 border rounded mb-2"
          placeholder={t('enterValue')}
        />
        <div className="flex space-x-2 mb-2">
          <select
            value={imperialFromUnit}
            onChange={(e) => setImperialFromUnit(e.target.value)}
            className="w-1/2 p-2 border rounded"
          >
            {imperialDensityUnits.map(unit => (
              <option key={unit.name} value={unit.name}>{getTranslatedLabel(unit)}</option>
            ))}
          </select>
          <select
            value={imperialToUnit}
            onChange={(e) => setImperialToUnit(e.target.value)}
            className="w-1/2 p-2 border rounded"
          >
            {imperialDensityUnits.map(unit => (
              <option key={unit.name} value={unit.name}>{getTranslatedLabel(unit)}</option>
            ))}
          </select>
        </div>
        <button
          onClick={handleImperialConvert}
          className="w-full p-2 bg-orange-400 text-white font-semibold py-3 px-6 rounded"
        >
          {t('convertImperialUnits')}
        </button>
        {imperialResult && (
          <div className="mt-2 p-2 bg-gray-100 rounded">
            <p>{t('result')}: {imperialResult.formattedValue} {getUnitSymbol(imperialToUnit)}</p>
            <p>{t('scientificNotation')}: {imperialResult.scientificNotation} {getUnitSymbol(imperialToUnit)}</p>
          </div>
        )}
      </div>

      {/* Cronologia delle conversioni */}
      {conversionHistory.length > 0 && (
        <div className="mt-4">
          <h2 className="text-lg font-semibold mb-2">{t('conversionHistory')}</h2>
          <ul className="space-y-2">
            {conversionHistory.map((item, index) => (
              <li key={index} className="p-2 bg-gray-50 rounded">
                {item.from} → {item.to}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DensityConversion;